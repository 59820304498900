import {
    Book,
    GraduationCap,
    LayoutDashboard,
    LucideIcon,
    UserCog,
    Users,
} from "lucide-react";

export type PublicLinksTranslation =
    | "links.home"
    | "links.courses"
    | "links.team"
    | "links.aboutUs"
    | "links.contactUs"
    | "links.admins"
    | "links.instructors"
    | "links.dashboard";

export type PrivateLinksTranslation =
    | "links.home"
    | "links.courses"
    | "links.certificateRequests"
    | "links.team"
    | "links.aboutUs"
    | "links.contactUs"
    | "links.admins"
    | "links.instructors"
    | "links.dashboard";
export type NavbarLink = {
    name: PublicLinksTranslation | PrivateLinksTranslation;
    url: string;
    icon?: LucideIcon;
    role: "super-admin" | "admin" | "instructor" | "student";
};

export const publicLinks: NavbarLink[] = [
    {
        name: "links.home",
        url: "/",
        role: "student",
    },
    {
        name: "links.courses",
        url: "/courses",
        role: "student",
    },
    {
        name: "links.aboutUs",
        url: "/about",
        role: "student",
    },
    {
        name: "links.contactUs",
        url: "/contact",
        role: "student",
    },
];

export const adminLinks: NavbarLink[] = [
    {
        name: "links.dashboard",
        url: "/admin/dashboard",
        icon: LayoutDashboard,
        role: "instructor",
    },
    {
        name: "links.courses",
        url: "/admin/courses",
        icon: Book,
        role: "instructor",
    },
    {
        name: "links.certificateRequests",
        url: "/admin/requests",
        icon: GraduationCap,
        role: "admin",
    },
    {
        name: "links.instructors",
        url: "/admin/instructors",
        icon: Users,
        role: "admin",
    },
    {
        name: "links.admins",
        url: "/admin/admins",
        icon: UserCog,
        role: "super-admin",
    },
];
