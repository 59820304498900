import { cn } from "@/lib/utils";
import { Link, InertiaLinkProps } from "@inertiajs/react";
import { usePage } from "@inertiajs/react";

export default function NavLink({
    href,
    className = "",
    children,
    ...props
}: InertiaLinkProps & { href: string }) {
    const { url } = usePage();

    const isActive = () => {
        // URL exact match...
        if (url === href) return true;

        return false;
    };

    return (
        <Link
            {...props}
            href={href}
            className={cn(
                "inline-flex items-center w-fit px-2 text-muted-foreground text-md font-medium leading-5 transition duration-150 ease-in-out focus:outline-none hover:bg-primary hover:text-popover rounded-lg ",
                isActive() && "text-primary font-bold",
                className
            )}
        >
            {children}
        </Link>
    );
}
